<template>
  <div
    class="deactive-brand"
    ref="mod"
    v-show="configs.modalType == 'deactive'"
  >
    <template>
      <artmisads-modal
        title="Are you sure you want to Deactivate this Brand?"
        v-model:open="configs.open"
        @ok="onOk"
        @cancel="onModalCancel"
        style="width: 600px"
        :getContainer="() => $refs.mod"
        okText="Confirm"
      >
        <div class="deactive">
          Deactivating a brand on the Artemis Ads Platform will deactivate all
          associated products, archive active publisher/creator links, and make
          the brand and its products unavailable to publishers and creators.
          Please note that this action does NOT deactivate the brand on Amazon.
        </div>
      </artmisads-modal>
    </template>
  </div>
  <div
    class="set-commission"
    ref="set"
    v-show="configs.modalType == 'setCommission'"
  >
    <template>
      <artmisads-modal
        v-model:open="configs.open"
        @ok="onOk"
        @cancel="onModalCancel"
        style="width: 600px"
        :getContainer="() => $refs.set"
        title="Set Public Commission for all Products under this Brand"
      >
        <template #footer>
          <artmisads-button key="back" type="primary" @click="onOk"
            >Save Commission</artmisads-button
          >
        </template>
        <div class="set-commission-content">
          <p class="first-p">
            The public commission is displayed to all publishers/creators when the product is active on the platform. Artemis Ads Fees are displayed to you and Artemis Ads only.
          </p>
          <div class="content">
            <div class="commission">
              <div class="commission-input">
                <span>Affiliate Commission</span>
                <artmisads-input-number
                  style="width: 100%"
                  placeholder="Please enter..."
                  v-model:value="setCommission.commission"
                  :step="0.1"
                  min="0"
                  max="100"
                  :formatter="(value) => `${value}`"
                  :parser="(value) => value.replace('', '')"
                  @change="onSetCommission"
                  size="large"
                  suffix="%"
                >
                </artmisads-input-number>
              </div>
              <span>+</span>
              <div class="fees">
                <span>ArtemisAds Fees</span>
                <artmisads-button disabled>{{
                  setCommission.commissionFeeds
                }}</artmisads-button>
              </div>
              <span>=</span>
              <div class="total">
                <span>Total</span>
                <artmisads-button disabled>
                  <span>{{ setCommission.totalCommission }}</span>
                  <span>%</span>
                </artmisads-button>
              </div>
            </div>
            <p class="second-p">
              You will receive avg 10% of Qualified Sales as Brand Referral
              Bonus from Amazon.
            </p>
            <p class="third-p">
              The percentages displayed above will be applied to sales data that Artemis Ads receives from
              Amazon Attribution. Please note that sales reported by Amazon Attribution excludes Discounts
              (Instant Discount), Prime Day Deals, Prime Exclusive Deals, and Limited Time Deals, while
              DOES NOT exclude discounts from Promo Codes, Chippable Coupons, Subscribe & Save, or
              Lightning Deals.
            </p>
          </div>
        </div>
        <!-- <div  class="set-commission" >
        <div class="title-ext">The public commission is visible to all creators when the product is active in the marketplace</div>
        <div class="content">
          
          <div class="commission">
            <p class="tip">
              Affiliate Commission
            </p>
            <div class="commission-number">
              <a-input-number
              style="width: 100%;"
              placeholder="Please enter..."
              v-model:value="setCommission.commission"
              :step="0.1"
              min="0"
              max="100"
              :formatter="value => `${value}`"
              :parser="value => value.replace('', '')"
              @change="onSetCommission"
              size="large"
              >
              <template #upIcon>
                <svg class="icon" aria-hidden="true" font-size="12px">
                  <use xlink:href="#icon-jiantou-shang-cu"></use>
                </svg>
              </template>
              <template #downIcon>
                <svg class="icon" aria-hidden="true" font-size="12px">
                  <use xlink:href="#icon-jiantou-xia-cu"></use>
                </svg>
              </template>
              </a-input-number>
              <div class="commission-suffix">%</div>
            </div>
          </div>
          <div>
            <p style="visibility: hidden;margin-bottom: 6px">+</p>
            <div type="text" class="math">
              +
            </div>
          </div>
          <div class="feed">
            <p class="tip">
              ArtemisAds Fees
            </p>
            <a-input disabled v-model:value="setCommission.commissionFeeds"
            style="background-color: #EBE9F0;"
            size="large"
            ></a-input>
          </div>
          <div>
            <p style="visibility: hidden; margin-bottom: 6px;">=</p>
            <div type="text" class="math">=</div>
          </div>
          <div class="total">
            <p class="tip">Total</p>
            <a-input disabled  
            v-model:value="setCommission.totalCommission" 
            placeholder="--" 
            suffix="%"
            size="large"
            style="background-color: #EBE9F0;"
            >
            
            </a-input>

          </div>
        </div>
        <div class="total-ext">
          <div >You will receive avg 10% of Qualified Sales as Brand Referral Bonus from Amazon</div>
      
        </div>
        <p  class="third-p">The percentages shown above will be applied to the sales date ArtemisAds receives from 
          Amazon Attribution, Please Notes that sales reported by Amazon Attribution do not include 
          discounts applied from promo codes, coupons, subscribe save, or lightning deals.</p>
        </div> -->
      </artmisads-modal>
    </template>
  </div>
  <div ref="act" v-show="configs.modalType == 'active'">
    <template>
      <artmisads-modal
        v-model:open="configs.open"
        @ok="onOk"
        @cancel="onModalCancel"
        :getContainer="() => $refs.act"
        style="width: 600px"
        title="Are you sure you want to Activate this Brand?"
        okText="Confirm"
      >
        <div class="active-brand">
          <div class="title-ext">
            Activating the brand will allow you to enable products under this brand and list them in the Publisher/Creator Marketplace.
          </div>
          <div class="brand-name-row">
            <div class="brand-name">
              {{ item.amazonBrandName }}
            </div>
          </div>
          <div class="content">
            I acknowledge that activating this brand will enable my seller
            account in <span class="login-country">{{ country }}.</span> Once
            activated, I will be activate in the following countries:
            {{ country }} and will have used 1 of my available 1 countries
          </div>
        </div>
      </artmisads-modal>
    </template>
  </div>
  <div
    class="update-brand-profile"
    ref="profile"
    v-show="configs.modalType == 'updateProfile'"
  >
    <template>
      <artmisads-modal
        v-model:open="configs.open"
        :getContainer="() => $refs.profile"
        @ok="onOk"
        title="Update Brand Profile"
        style="width: 600px"
      >
        <template #footer>
          <div class="footer-btn">
            <artmisads-button
              key="back"
              type="primary"
              @click="onOk"
              :disabled="isDisabled"
              >Save</artmisads-button
            >
          </div>
        </template>
        <div class="content">
          <a-form
            :label-col="{ style: { width: '60px' } }"
            :wrapper-col="{ span: 20 }"
            layout="horizontal"
            style="color: aquamarine"
          >
            <a-form-item label="Image">
              <UploadImage
                :configs="{
                  maxCount: 1,
                  maxSize: 2,
                  minWidth: 600,
                  minHeight: 600,
                  spin: true,
                }"
                @upload-file="uploadImg"
                #content
              >
                <div class="upload_part">
                  <div class="upload">
                    <div v-if="brandProfile.logoImageUrl" class="upload-img">
                      <img :src="brandProfile.logoImageUrl" alt="" />
                    </div>
                    <div v-else class="upload-icon">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-shangchuan-xi"></use>
                      </svg>
                    </div>
                  </div>
                  <artmisads-select-button
                    size="small"
                    v-if="brandProfile.logoImageUrl"
                    >Replace Image</artmisads-select-button
                  >
                </div>
              </UploadImage>
              <div class="suggested">Suggested image size 600px * 600px</div>
            </a-form-item>
            <a-form-item label="Bio">
              <a-textarea
                :rows="4"
                maxLength="1000"
                placeholder="Please enter..."
                v-model:value="brandProfile.biography"
                @change="onBios"
                @blur="onValiadBios"
              />
              <span v-show="biosValid" style="color: #eb4e3d"
                >Maximum word count 1000</span
              >
            </a-form-item>
          </a-form>
        </div>
      </artmisads-modal>
    </template>
  </div>
</template>
<script setup name="tableModal">
import { ref, reactive, toRefs, computed, watch, watchEffect } from "vue";
import { message } from "ant-design-vue";
import {
  UpOutlined,
  DownOutlined,
  PlusOutlined,
  PauseOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons-vue";
import { useUserInfoStore } from "@/storeData";
import { COUNTRY_FULL_NAME } from "@/contants/layout";
import { useSystemStore } from "@/storeData/system";
import UploadImage from "@/components/uploadImage.vue";

const { configs, item } = defineProps({
  configs: {
    type: Object,
    default: () => {},
  },
  item: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(["cancel", "ok"]);
const systemStore = useSystemStore();
const user = useUserInfoStore();
const mod = ref();
const profile = ref();
const set = ref();
const act = ref();
let nameChecked = ref();
const brandProfile = reactive({});
const isDisabled = ref(true); //按钮禁用
const setCommission = reactive({
  commissionFeeds: "",
  commission: "",
  totalCommission: "",
});
let biosValid = ref(false);
const country = computed(() => {
  return COUNTRY_FULL_NAME[user.userInfo.countryCode];
});
watch([() => configs.modalType], (newVal) => {
  if (newVal == "updateProfile") {
    Object.assign(brandProfile, item);
  }
});
watch([() => item?.id, () => configs.open], (newVal) => {
  if (configs.modalType == "setCommission" && configs.open) {
    const { defaultCommissionRate, platformFeeFactor, platformMaxFeeRate } =
      systemStore.commissionConfig;
    let _commissionRate = defaultCommissionRate;
    if (item?.commissionRate) {
      setCommission.commission = item.commissionRate;
      _commissionRate = item.commissionRate;
      let fees = parseFloat(
        (_commissionRate * platformFeeFactor) / 100
      ).toFixed(1);
      if (parseFloat(fees) > platformMaxFeeRate) {
        fees = platformMaxFeeRate;
      }
      setCommission.totalCommission = (
        parseFloat(_commissionRate) + parseFloat(fees)
      ).toFixed(1);
      setCommission.commissionFeeds = fees + "%";
    } else {
      setCommission.commission = undefined;
      setCommission.totalCommission = undefined;
      setCommission.commissionFeeds =
        parseInt(
          (((Number(_commissionRate) / 100) * Number(platformFeeFactor)) /
            100) *
            100
        ).toFixed(1) + "%";
    }
  }
});
const onSetCommission = (e) => {
  setCommission.commission = e;
  const { defaultCommissionRate, platformFeeFactor, platformMaxFeeRate } =
    systemStore.commissionConfig;
  let number = parseFloat(e.toFixed(1));
  if (isNaN(parseFloat(e))) {
    setCommission.commission = undefined;
    setCommission.totalCommission = undefined;
    return;
  }
  setCommission.commission = Math.floor(number * 10) / 10; //一位小数
  let _commissionFact = parseFloat(
    (parseFloat(setCommission.commission) / 100) *
      parseFloat(platformFeeFactor / 100) *
      100
  ).toFixed(1);
  if (_commissionFact > Number(platformMaxFeeRate)) {
    setCommission.commissionFeeds = platformMaxFeeRate;
  } else {
    setCommission.commissionFeeds = _commissionFact;
  }
  let code =
    parseFloat(setCommission.commission) +
    parseFloat(setCommission.commissionFeeds);
  setCommission.commissionFeeds = setCommission.commissionFeeds + "%";
  setCommission.totalCommission = code.toFixed(1);
};
const onModalCancel = (e) => {
  emit("cancel");
};
const onActiveBrand = (item = "check") => {
  if (item == "button") {
    nameChecked.value = !nameChecked.value;
  }

  if (nameChecked.value) {
    isDisabled.value = false;
  } else {
    isDisabled.value = true;
  }
};
const uploadImg = (res) => {
  try {
    if (res.data.url !== item.logoImageUrl) {
      isDisabled.value = false;
    }
    brandProfile.logoImageUrl = res.data.url;
  } catch (error) {
    console.log("upload-false");
  }
};
const onBios = (e) => {
  biosValid.value = false;
  if (e.target.value !== item.biography) {
    isDisabled.value = false;
  }
};
const onValiadBios = (e) => {
  // if (item.biography.length >= 1000) {
  if (brandProfile.biography && brandProfile.biography.length >= 1000) {
    biosValid.value = true;
  }
};
const onOk = (e) => {
  let res = { ...item };
  if (configs.modalType == "setCommission") {
    res.commissionRate = setCommission.commission;
    if (!res.commissionRate) {
      message.error("please input commission!", 1);
      return;
    }
  }
  if (configs.modalType == "updateProfile") {
    isDisabled.value = true;
    if (biosValid.value) {
      return;
    }
    res = { ...res, ...brandProfile };
  }
  emit("ok", res);
};
</script>
<style lang="less" scoped>
@space: 16px;
@second-font: {
  opacity: 0.5;
  font-family: Sora-Regular;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  line-height: 22px;
};
.title-ext {
  @second-font();
  margin-bottom: @space;
}
.modal-title {
  border-radius: 8px 8px 0 0;
  font-family: Sora-Medium;
  font-weight: 500;
  font-size: 20px;
  color: var(--dark-1);
  letter-spacing: 0.5px;
  line-height: 27px;
  margin-right: 16px;
}

.footer {
  display: flex;
  justify-content: flex-start;
}
.deactive-brand {
  .deactive {
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 22px;
    // padding: 24px 17px 24px 24px;
  }
}
.set-commission {
  // :deep(.ant-modal) {
  //   .ant-modal-body {
  //     padding: 0;
  //     border: none;
  //   }
  // }
  .set-commission-content {
    p {
      font-family: Sora-Regular;
    }
    .first-p {
      padding: 8px 0 24px;
      color: var(--dark-3);
      font-weight: 400;
      line-height: 22px;
    }
    .third-p {
      margin-top: 28px;
      background: var(--big-bg-2);
      border-radius: 8px;
      padding: 8px 12px;
      font-size: var(--font-size-min);
      color: var(--dark-3);
      letter-spacing: 0.2px;
      line-height: 18px;
    }
    .second-p {
      font-size: var(--font-size-min);
      align-self: end;
      padding: 8px 12px;
      margin-top: 15px;
      width: 286px;
      background: var(--color-white);
      border: 1px solid var(--border-4);
      box-shadow: var(--section-box-shadow-2);
      border-radius: 8px;
      color: var(--dark-3);
      letter-spacing: 0.2px;
      line-height: 16px;
      position: relative;
      font-style: italic;
      &::before {
        content: "";
        position: absolute;
        top: -5px;
        right: 130px;
        width: 8px;
        height: 8px;
        border: 1px solid var(--border-1);
        border-color: var(--border-4) transparent transparent var(--border-4);
        background-color: #ffffff;
        transform: rotate(45deg);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    // padding: 0 24px 24px 24px;
    .commission {
      display: flex;
      > span {
        align-self: center;
        margin: 20px 10px 0 10px;
        font-family: Sora-Regular;
        font-weight: 500;
        color: #0f0629;
      }
      .fees,
      .total,
      .commission-input {
        display: flex;
        flex-direction: column;
        > span {
          margin-bottom: 6px;
          font-weight: 500;
          color: var(--dark-2);
          line-height: 19px;
        }
      }

      .total {
        > button {
          width: 96px;
          display: flex;
          justify-content: space-between;
          > span {
            align-self: center;
          }
        }
      }
      .fees {
        > button {
          width: 144px;
        }
      }
      .fees,
      .total {
        > button {
          padding-left: 12px;
          text-align: left;
          border-radius: 8px;
          font-weight: 500;
          font-size: var(--font-size-large);
          color: var(--dark-1);
        }
      }
    }
  }
}
// .set-commission{
//   padding: 24px 17px 24px 24px;
//   .content{
//     display: flex;
//     justify-content: space-between;
//     width: 90%;
//     .tip{
//       opacity: 0.7;
//       font-family: Sora-Medium;
//       font-weight: 500;
//       font-size: 14px;
//       color: var(--dark-1);
//       letter-spacing: 0.4px;
//       line-height: 19px;
//       margin-bottom: 8px;
//     }
//     .math{
//       height: 30px;
//       font-weight: 500;
//       font-size: 14px;
//       color: var(--dark-1);
//       letter-spacing: 0.4px;
//       line-height: 30px;
//     }
//     .commission{
//       width: 35%;

//       :deep{
//         .ant-input-number-input{
//           font-weight: 500;
//           font-size: 16px;
//           color:var(--dark-1);
//           letter-spacing: 0.4px;
//           line-height: 22px;
//         }
//         .ant-input-number-handler-wrap{
//           width: 30px;
//         }
//         .anticon{
//           font-weight: 400;
//           font-size: 12px;
//           color: var(--dark-2);

//         }
//       }
//       .commission-number{
//         position: relative;
//       }
//       .commission-suffix{
//         position:absolute;
//         top:0;
//         right: 30px;
//         width: 22px;
//         height: 40px;
//         font-weight: 500;
//         font-size: 16px;
//         color: var(--dark-1);
//         letter-spacing: 0.4px;
//         line-height: 40px;
//         z-index: 2;

//       }
//     }
//     .feed:deep(.ant-input-disabled),.total:deep(.ant-input-disabled){
//       font-weight: 500;
//       font-size: 16px;
//       color: var(--dark-1);
//       letter-spacing: 0.4px;
//       // line-height: 22px;
//       box-shadow: unset !important;
//     }
//     .feed{
//       width: 28%;
//     }
//     .total:deep{
//       width:18%;
//       .ant-input-affix-wrapper{
//         background-color: #EbE9f0;
//         font-weight: 500;
//         font-size: 16px;
//         color:var(--dark-1);
//         letter-spacing: 0.4px;
//         line-height: 22px;
//       }
//     }

//   }
//   .total-ext{
//     display: flex;
//     justify-content: flex-end;
//     margin-top: 8px;
//     margin-bottom: @space;
//     >div{
//       position: relative;
//       width: 270px;
//       opacity: 0.5;
//       font-family: Sora-Regular;
//       font-weight: 400;
//       font-size: 12px;
//       color: var(--dark-1);
//       letter-spacing: 0.2px;
//       line-height: 16px;
//       border:1px solid var(--border-1);
//       padding: 8px;
//       border-radius: 8px;
//       &::before{
//         content: '';
//         position: absolute;
//         top: -5px;
//         right: 70px;
//         width: 8px;
//         height: 8px;
//         border: 1px solid var(--border-1);
//         border-color: var(--border-1) transparent transparent var(--border-1);
//         background-color:#ffffff;
//         transform: rotate(45deg);
//       }
//     }
//   }
//   .third-p {
//     margin-top: 28px;
//     background: #F9F7FC;
//     border-radius: 8px;
//     padding: 8px 12px;
//     font-family: Sora-Regular;
//     font-weight: 400;
//     font-size: 12px;
//     color: rgba(15, 6, 41, .5);
//     letter-spacing: 0.2px;
//     line-height: 18px;
//   }
// }
.active-brand {
  // padding: 24px 17px 24px 24px;
  .brand-name-row {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
  }
  .brand-name {
    font-family: Sora-Medium;
    font-weight: 500;
    font-size: 18px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 21px;
  }
  .content {
    font-family: Sora-Regular;
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 22px;
  }
  // .login-country{
  //   color: #999;
  // }
}
.update-brand-profile {
  :deep(label) {
    @second-font();
    opacity: 0.7;
    font-weight: 500;
    line-height: 20px;
  }
  .upload_part {
    display: flex;
    align-items: flex-end;
  }
  .upload {
    width: 120px;
    height: 120px;
    background: var(--big-bg-1);
    border-radius: 8px;
    margin-right: 8px;
    .upload-icon {
      border: 2px dashed var(--border-1);
      border-radius: 8px;
      @second-font();
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      &:hover {
        background-color: var(--bg-gray-2);
        border-color: var(--bg-gray-7);
      }
    }
    .upload-img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .suggested {
    @second-font();
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-top: 4px;
  }
}
</style>
