import { defineStore } from 'pinia'
import { ref, computed, watch, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { adminApi } from '../server'

export const useSystemStore = defineStore(
  'system',
  () => {
    // const router = useRouter();
    // const menus = ref(
    //   router.options.routes
    //     .find(item => item.name === 'admin')
    //     .children.filter(item => item.name !== 'welcome'),
    // )
    // const layoutBreads=ref({
    //   pageName:'',
    //   title:'',
    //   preface:''
    // });
    // const setPageTitle=(layoutBreadsBase)=>{
    //   layoutBreads.value={...layoutBreads,...layoutBreadsBase}
    // }
    const commissionConfig = ref({});
    const getCommissionConfig= ()=>{
      adminApi.getCommissionConfig().then(res=>{
        commissionConfig.value=res.data
      })
    }
    getCommissionConfig();
    return {
      commissionConfig,
      getCommissionConfig
    }
  }
)
